// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stories-js": () => import("./../../../src/pages/stories.js" /* webpackChunkName: "component---src-pages-stories-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-faqs-page-js": () => import("./../../../src/templates/faqs-page.js" /* webpackChunkName: "component---src-templates-faqs-page-js" */),
  "component---src-templates-help-page-js": () => import("./../../../src/templates/help-page.js" /* webpackChunkName: "component---src-templates-help-page-js" */),
  "component---src-templates-impact-page-js": () => import("./../../../src/templates/impact-page.js" /* webpackChunkName: "component---src-templates-impact-page-js" */),
  "component---src-templates-implement-page-js": () => import("./../../../src/templates/implement-page.js" /* webpackChunkName: "component---src-templates-implement-page-js" */),
  "component---src-templates-invest-page-js": () => import("./../../../src/templates/invest-page.js" /* webpackChunkName: "component---src-templates-invest-page-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-terms-page-js": () => import("./../../../src/templates/terms-page.js" /* webpackChunkName: "component---src-templates-terms-page-js" */)
}

